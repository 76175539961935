import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../components/Layout/Layout'
import * as styles from './404.module.scss'
import Seo from '../components/Seo/Seo'

interface Props {
	location: Location
}

export default ({ location }: Props) => (
	<Layout>
		<Seo title='Nerasta' pathName={location.pathname} />
		<Container>
			<Row>
				<Col>
					<div className={styles.content}>
						<h1>Apgailestaujame, šio puslapio nepavyko rasti!</h1>
					</div>
				</Col>
			</Row>
		</Container>
	</Layout>
)
